import { Cubit } from "blac-next";
import {
  AppointmentControllerService,
  AppointmentResponse,
  OpenAPI,
  UserShortLivedTokenControllerService
} from "@9amhealth/openapi";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { toast, tracker } from "src/state/state";
import SanityService from "src/api/SanityService";
import sanityQuery from "src/lib/sanityQuery";
import { getSupportedUserLanguage } from "src/lib/i18next";
import type { TranslationKey } from "src/types/translationKey";
import {
  IconCalendarPlus,
  IconClock,
  IconVideoRecorder
} from "src/constants/icons";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import React from "react";
import { isZoomLink } from "@9amhealth/shared";
import { OpenBrowser } from "src/hybrid/components/Browser";
import {
  getLocalTimeZone,
  now,
  parseAbsoluteToLocal
} from "@internationalized/date";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import { globalEvents } from "src/constants/globalEvents";
import status = AppointmentResponse.status;

export interface AppointmentsState {
  scheduledAppointments: Appointment[];
}

export interface AppointmentSanityDetails {
  eventName: string;
  joiningInstructions: string;
  language: string;
  _type: string;
  type: string;
  _rev: string;
  avatar?: Avatar;
  _createdAt: string;
  eventContent: EventContent[];
  _id: string;
  title: string;
  _updatedAt: string;
}

export interface Avatar {
  _type: string;
  assetId: string;
  url: string;
}

export interface EventContent {
  contentTitle: string;
  _key: string;
  content: Content[];
}

export interface Content {
  level?: number;
  _type: string;
  style: string;
  _key: string;
  listItem?: string;
  children: Children[];
}

export interface Children {
  _type: string;
  marks: string[];
  text: string;
  _key: string;
}

export interface ActionConfig {
  label: TranslationKey;
  icon: React.ReactNode;
  onClick: (appointment: AppointmentResponse) => void;
  visible?: (appointment: AppointmentResponse) => boolean;
}

export interface AppointmentConfig {
  primaryAction: ActionConfig;
  secondaryAction?: ActionConfig;
  tertiaryAction?: ActionConfig;
  detailsAvailable?: boolean;
}

const defaultAppointmentConfig: AppointmentConfig = {
  primaryAction: {
    label: "appointment.action.label.addToCalendar",
    icon: <IconCalendarPlus />,
    onClick: (appointment: AppointmentResponse) => {
      tracker.track(TrackEvent.AppointmentAddToCalClick, {
        type: TrackType.click,
        data: {
          appointmentId: appointment.id
        }
      });

      void AppointmentsBloc.downloadIcsFile(appointment.id);
    }
  }
};

export type Appointment = AppointmentResponse & { config: AppointmentConfig };

class AppointmentsBloc extends Cubit<AppointmentsState> {
  appointments: Appointment[] = [];

  appointmentConfig: Record<
    AppointmentResponse.type,
    AppointmentConfig | undefined
  > = {
    [AppointmentResponse.type.SYNC_VISIT]: {
      detailsAvailable: true,
      primaryAction: defaultAppointmentConfig.primaryAction,
      secondaryAction: {
        label: "appointment.action.label.joinCall",
        visible: (appointment: AppointmentResponse) =>
          isZoomLink(appointment.location),
        icon: <IconVideoRecorder />,
        onClick: (appointment: AppointmentResponse) => {
          tracker.track(TrackEvent.AppointmentJoinCallClick, {
            type: TrackType.click,
            data: {
              location: appointment.location
            }
          });

          if (!appointment.location) {
            return;
          }

          window.open(appointment.location, "_blank");
        }
      },
      tertiaryAction: {
        visible: (appointment: AppointmentResponse) =>
          appointment.status === status.SCHEDULED,
        label: "appointment.action.label.reschedule",
        icon: <IconClock />,
        onClick: (appointment: AppointmentResponse) => {
          tracker.track(TrackEvent.AppointmentRescheduleClick, {
            type: TrackType.click,
            data: {
              appointmentId: appointment.id
            }
          });

          AppQueryPopupsController.openPopup(AppPopup.rescheduleAppointment, {
            onEvent: {
              [globalEvents.APPOINTMENT_RESCHEDULED]: () => {
                void this.loadAppointments();
              }
            },
            additionalParameters: {
              id: appointment.id,
              stay: "false"
            }
          });
        }
      }
    }
  };

  constructor() {
    super({
      scheduledAppointments: []
    });

    void this.loadAppointments();
  }

  private setAppointmentsConfig = (appointments: AppointmentResponse[]) => {
    return appointments.map((appointment) => {
      return {
        ...appointment,
        config:
          this.appointmentConfig[appointment.type] ?? defaultAppointmentConfig
      };
    });
  };

  public readonly loadAppointments = async () => {
    try {
      const { data } =
        await AppointmentControllerService.getScheduledAppointments();

      const appointments = this.setAppointmentsConfig(data);

      this.appointments = appointments;

      const scheduled = appointments.filter(
        (appointment) => appointment.status === status.SCHEDULED
      );

      const notExpired = scheduled.filter((appointment) => {
        const asZoned = parseAbsoluteToLocal(appointment.end);

        const today = now(getLocalTimeZone());

        const fifteenMinutesInMs = 900000;

        // filter appointments that aren't expired by 15 minutes
        return today.compare(asZoned) < fifteenMinutesInMs;
      });

      // sort by start date
      const sorted = notExpired.sort((a, b) => {
        return parseAbsoluteToLocal(a.start).compare(
          parseAbsoluteToLocal(b.start)
        );
      });

      this.patch({
        scheduledAppointments: sorted
      });
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  static getAppointmentReschedulingUrl = async (appointmentId: string) => {
    const { data } =
      await AppointmentControllerService.initiateRescheduling(appointmentId);

    const { schedulingUrl } = data;

    return schedulingUrl;
  };

  static downloadIcsFile = async (appointmentId: string) => {
    try {
      const res =
        await UserShortLivedTokenControllerService.retrieveShortLivedToken();

      const { accessToken } = res.data;

      const fileUrl = `${OpenAPI.BASE}/v1/appointments/${appointmentId}.ics?authToken=${accessToken}`;

      void OpenBrowser(fileUrl, {
        presentationStyle: "popover",
        useBaseUrl: false
      });
    } catch (error) {
      toast.show("error_failed_appointment_calendar");

      reportErrorSentry(error);
    }
  };

  static getAppointmentCmsDetails = async (type: AppointmentResponse.type) => {
    const language = getSupportedUserLanguage();

    const appointmentDetails: AppointmentSanityDetails[] =
      await SanityService.fetchSanityData(
        sanityQuery.appointmentDetailsByType(type, language)
      );

    return appointmentDetails[0];
  };

  public getAppointment = (id: string) => {
    return this.appointments.find((appointment) => id === appointment.id);
  };

  get nextAppointment(): Appointment | undefined {
    return this.state.scheduledAppointments[0];
  }
}

export default AppointmentsBloc;
